@font-face {
  font-family: Google Sans;
  src: url('./fonts/GoogleSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Google Sans;
  src: url('./fonts/GoogleSans-Medium.woff2');
  font-weight: 500;
}

@font-face {
  font-family: Google Sans Display;
  src: url('./fonts/Google-Sans-Display.woff2');
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url('./fonts/Roboto.woff2');
  font-weight: 400;
}
