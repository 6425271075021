.container {
  width: 100%;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 9;
  background-color: #fff;
  transition: all 0.3s;
  .containerTop {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 10px;
    > div {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .containerTopL {
      .googleIcon1 {
        height: 40px;
        object-fit: contain;
        cursor: pointer;
        display: block;
      }
      .googleIcon {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
      .containerTopLTitle {
        color: #5f6368;
        font-size: 22px;
        padding-left: 8px;
        font-family: 'Google Sans';
        font-weight: 500;
        cursor: pointer;
      }
      .containerTopLMenus {
        height: 100%;
        display: none;
        align-items: center;
        padding-left: 14px;
        .containerTopLMenuItem {
          margin: 0 20px;
          height: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgb(95, 99, 104);
          cursor: pointer;
          font-weight: 500;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: 0.25px;
          .itemBottom {
            width: 100%;
            height: 3px;
            border-radius: 3px;
            background-color: #01875f;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    .containerTopR {
      .containerTopRItem {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        &:hover {
          background-color: #f5f5f5;
        }
        .itemIcon {
          width: 24px;
          height: 24px;
        }
        .googleIcon {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
  .containerTopMenus {
    width: 100%;
    height: 48px;
    display: none;
    align-items: center;
    justify-content: center;
    .containerTopMenuItem {
      flex: 1;
      max-width: 112px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgb(95, 99, 104);
      cursor: pointer;
    }
  }
}

// PC
@media only screen and (min-width: 660px) {
  .container {
    .containerTop {
      height: 64px;
      padding-left: 32px;
      padding-right: 10px;
      .containerTopL {
        .containerTopLMenus {
          display: none;
        }
      }
    }
    .containerTopMenus {
      display: flex;
    }
  }
}

@media only screen and (min-width: 840px) {
  .container {
    .containerTop {
      .containerTopL {
        .containerTopLMenus {
          display: flex;
        }
      }
    }
    .containerTopMenus {
      display: none;
    }
  }
}
