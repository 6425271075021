.container {
  width: 100%;
  height: 56px;
  position: sticky;
  z-index: 9;
  left: 0;
  bottom: 0;
  background-color: #fff;
  border-top: 1px solid #dadce0;
  display: flex;
  align-items: center;
  .tabItem {
    flex: 1;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
    cursor: pointer;
    img {
      width: 25px;
      height: 25px;
    }
    div {
      width: 100%;
      text-align: center;
      margin-top: 2px;
      font-family: Google Sans;
      font-size: 14px;
      padding: 0 4px;
      font-weight: 500;
      color: #5f6368;
      line-height: 16px;
      letter-spacing: 0.25px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media only screen and (min-width: 660px) {
  .container {
    display: none;
  }
}
