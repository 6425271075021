body {
  font-family: inherit;
  font-size: 14px;
  font-family: 'Google Sans', Roboto, Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

@import url('assets/font.css');
